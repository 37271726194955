import {
  EVENT_PREFIX,
  getFormData,
  getFieldsMessages,
  getTouchedFields,
  getMetaValue,
  getPreviousFormData,
  isSubmitting
} from '../../helpers';
import async from 'async';
import {
  updateData,
  validate as validateAction,
  setList as setListAction,
  meta,
  updatePreviousFormData,
  setSubmitting
} from '../../reducer';

let debounces = {};

let lastFormData = {};

export default (store, action, next) => {
  const callOnValid = () => {
    if (typeof action.onValid !== 'function') {
      return;
    }

    const refreshedState = store.getState();

    const submitting = isSubmitting(refreshedState, action.instanceKey, action.stateKey);

    const validationMessages = getFieldsMessages(
      refreshedState,
      fields,
      action.instanceKey
    );

    let hasErrors = false;

    const formData = getFormData(refreshedState, action.instanceKey, action.stateKey);
    const result = {};

    fields.forEach(field => {
      if (!validationMessages.hasOwnProperty(field)) {
        return;
      }

      let isVisible = getMetaValue(refreshedState, field, 'isVisible', action.instanceKey, action.stateKey);

      if (isVisible === undefined) {
        isVisible = true;
      }

      // Ignore invisible fields.
      if (!isVisible) {
        return;
      }

      result[field] = formData[field];

      hasErrors = hasErrors || (validationMessages[field].find(m => m.severity === 'error') !== undefined);
    });

    if (!hasErrors && !submitting) {
      store.dispatch(setSubmitting(action.instanceKey, action.stateKey));
      action.onValid(result);
    }
  };

  const callOnDataChange = (dispatch) => {
    if (typeof action.formConfig.onDataChanged !== 'function') {
      callOnValid();
      return;
    }

    let shouldUpdateMeta = false;

    const setValues = (data, noOverwrite = false) => {
      shouldUpdateMeta = true;
      store.dispatch(updateData({
        instanceKey: action.instanceKey,
        data,
        noOverwrite
      }));
    };

    const setDefaultValues = (data) => {
      setValues(data, true);
    };

    const validate = (onlyTouched) => {
      store.dispatch(validateAction({
        onlyTouched,
        instanceKey: action.instanceKey,
        formConfig: action.formConfig,
        formContext: action.formContext
      }));
    };

    const setList = (name, list) => {
      store.dispatch(setListAction({
        stateKey: action.stateKey,
        instanceKey: action.instanceKey,
        name,
        list
      }));
    };

    // TODO: Validation results aan toevoegen
    const onDataChangedContext = {
      data: formData,
      field: action.field,
      whoop: 'daboop',
      previousData: previousFormData,
      formContext: action.formContext,
      validation: getFieldsMessages(store.getState(), Object.keys(action.formConfig.fields), action.instanceKey, action.stateKey),
      setValues,
      setDefaultValues,
      validate,
      setList
    };

    if (action.formConfig.onDataChanged.length === 2) {
      dispatch({
        type: EVENT_PREFIX + '_ON_DATA_CHANGED',
        instanceKey: action.instanceKey
      });

      action.formConfig.onDataChanged(onDataChangedContext, (err) => {
        if (err) {
          dispatch({
            type: EVENT_PREFIX + '_ON_DATA_CHANGED_ERROR',
            instanceKey: action.instanceKey,
            error: err
          });
        }

        dispatch({
          type: EVENT_PREFIX + '_ON_DATA_CHANGED_DONE',
          instanceKey: action.instanceKey
        });

        if (shouldUpdateMeta) {
          dispatch(meta({
            instanceKey: action.instanceKey,
            formConfig: action.formConfig,
            formContext: action.formContext
          }));
        }

        callOnValid();
      });
    } else {
      action.formConfig.onDataChanged(onDataChangedContext);

      if (shouldUpdateMeta) {
        dispatch(meta({
          instanceKey: action.instanceKey,
          formConfig: action.formConfig,
          formContext: action.formContext
        }));
      }

      callOnValid();
    }
  };

  const debounce = debounces[action.instanceKey];
  let fields = Object.keys(action.formConfig.fields);

  const theState = store.getState();

  if (action.onlyTouched) {
    const touchedFields = getTouchedFields(theState, action.instanceKey, action.stateKey);
    fields = touchedFields;
  }

  const formData = getFormData(theState, action.instanceKey, action.stateKey);
  const previousFormData = getPreviousFormData(theState, action.instanceKey, action.stateKey);

  store.dispatch(updatePreviousFormData({
    instanceKey: action.instanceKey,
    data: formData
  }));

  const previousFields = lastFormData[action.instanceKey] || {};

  const currentToCheck = {
    ...formData,
    ...fields
  };

  const lastToCheck = {
    ...previousFormData,
    ...previousFields
  };

  const allKeys = [
    ...Object.keys(formData),
    ...Object.keys(previousFields),
    ...Object.keys(previousFormData),
    ...Object.keys(fields)
  ];

  if (action.onlyTouched) {
    let different = false;

    allKeys.forEach(key => {
      if (currentToCheck[key] !== lastToCheck[key]) {
        different = true;
      }
    });

    if (!different) {
      // No need to refresh validation, no data has changed.
      callOnValid();
      return;
    }
  }

  lastFormData[action.instanceKey] = fields;

  const totalContext = {
    ...action.formContext,
    ...formData
  };

  const normalWork = [];
  const asyncWork = [];

  fields.forEach(key => {
    const fieldConfig = action.formConfig.fields[key];

    if (!fieldConfig) {
      return;
    }

    if (fieldConfig.rules) {
      fieldConfig.rules.forEach((rule, ruleIndex) => {
        if (!rule.isValid) {
          return;
        }

        // Function has a second parameter; assumption is that it's an async call
        if (rule.isValid.length === 2) {
          asyncWork.push(dispatch => fn => {
            rule.isValid(totalContext, (err, isValid) => {
              if (err) {
                return fn(err);
              }

              let message = null;

              if (isValid === false) {
                message = rule.message;

                if (typeof message === 'function') {
                  message = message(totalContext);
                }
              }

              dispatch({
                type: EVENT_PREFIX + '_VALIDATION_RESULT',
                instanceKey: action.instanceKey,
                field: key,
                ruleIndex,
                isValid,
                message,
                severity: rule.severity || (isValid ? null : 'error')
              });

              return fn();
            });
          });
        } else {
          normalWork.push(() => {
            const isValid = rule.isValid(totalContext);

            let message = null;

            if (isValid === false) {
              message = rule.message;

              if (typeof message === 'function') {
                message = message(totalContext);
              }
            }

            store.dispatch({
              type: EVENT_PREFIX + '_VALIDATION_RESULT',
              instanceKey: action.instanceKey,
              field: key,
              ruleIndex,
              isValid,
              message,
              severity: rule.severity || (isValid ? null : 'error')
            });
          });
        }
      });
    }
  });

  if (normalWork.length > 0) {
    normalWork.forEach(w => w());
  }

  if (asyncWork.length > 0) {
    next(action);

    if (debounce) {
      clearTimeout(debounce);
    }

    debounces[action.instanceKey] = setTimeout(() => {
      const currentDebounce = debounce;

      // We decorate the work with a custom dispatch function.
      // It may be that a new 'run' is started, in that case, we don't want the dispatches of the previous run
      const decorate = work => work((action) => {
        if (currentDebounce !== debounce) {
          return;
        }
        store.dispatch(action);
      });

      async.parallel(asyncWork.map(decorate), decorate(dispatch => (err) => {
        if (err) {
          dispatch({
            type: EVENT_PREFIX + '_VALIDATION_ERROR',
            instanceKey: action.instanceKey,
            error: err
          });
        }

        dispatch({
          type: EVENT_PREFIX + '_VALIDATION_DONE',
          instanceKey: action.instanceKey
        });

        if (currentDebounce !== debounce) {
          return;
        }

        callOnDataChange(store.dispatch);
      }));
    }, 250);
  } else {
    callOnDataChange(store.dispatch);
  }
};
